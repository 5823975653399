import React, {useState, useEffect} from 'react';
import './App.scss';
import Header from './components/Header'
import Description from "./components/Description";
import Form from "./components/Form";
import Advantages from "./components/Advantages";
import Modal from "./components/Modal";
import translations from './translations'
import {initAnalytics, AmplitudeWrapper} from './Analytics'
import Footer from "./components/Footer";

const LEGAL_DATA = {
    'getbill.info': {
        entity: 'Meetville',
        name: 'Avanta Inc.',
        address: '1470 Civic Court\n' +
            'Ste 309\n' +
            'Concord, CA 94520\n' +
            'United States',
        email: 'support@meetville.com',
        phoneNumber: '+1(201) 808-3130',
        tollFree: ''
    },
    'getbill.help': {
        entity: 'Loveland',
        name: 'Loveland inc.',
        address: '521 5th Avenue 17th Floor PMB #325 New York City, NY 10175 United States ',
        email: 'support@loveland.group',
        phoneNumber: '+1 (909) 324-3346',
        tollFree: '+ 1 (833) 776-0171'
    },
    'helpbill.co': {
        entity: 'Mindhub',
        name: 'Mindhub inc.',
        address: '402 West Broadway Suite 400, San Diego, California 92101, United States',
        email: 'support@mindhub.group',
        phoneNumber: '+1 (920) 533-0187',
        tollFree: ''
    },
    'getbill.me': {
        entity: 'Celebrese',
        name: 'Célebres e Efusivas - Unipessoal Lda',
        address: 'Rua Carlos Alberto Mota Pinto N 17 3 A Regus Business Centre Lisboa 1070-313 Lisboa',
        email: 'support@celebresefusivas.com',
        phoneNumber: '+351-300-600-402',
        tollFree: ''
    },
    'gotbilled.co': {
        entity: 'Cleverbirds',
        name: 'Cleverbirds, Inc.',
        address: '3101 N. Central Avenue, Suite XXX, Phoenix AZ 85012',
        email: 'support@cleverbirds.social',
        phoneNumber: '',
        tollFree: '+1 (855) 744-2375'
    },
    'billedme.info': {
        entity: 'Sequoia',
        name: 'SEQUOIA NETWORK, Inc.',
        address: '1255 Treat Blvd. Suite 300 Walnut Creek, California 94597',
        email: 'support@sequoiagroup.me',
        phoneNumber: '',
        tollFree: '+1 (855) 663-1703'
    },
    'igotbilled.help': {
        entity: 'Accel',
        name: 'ACCEL NETWORK, Inc.',
        address: '1470 Civic Court, Ste 309, Concord, CA 94520',
        email: 'support@accelsocial.net',
        phoneNumber: '',
        tollFree: '+1 (833) 668-1199'
    },
    'billed.help': {
        entity: 'Veo',
        name: 'Veo Live, Inc',
        address: '1470 Civic Court, Ste 309, Concord, CA 94520',
        email: 'support@veomedia.co',
        phoneNumber: '',
        tollFree: '+1 (844) 583-1366'
    },
    'billsupp.me': {
        entity: 'Cloudshadow',
        name: 'Cloudshadow Net, Kft.',
        address: '2300 Ráckeve, Dr. Mészáros László út 42, Hungary',
        email: 'support@cloudshadow.co',
        phoneNumber: '+36 1 655 5960',
        tollFree: ''
    },
    'probill.help': {
        entity: 'Recibar',
        name: 'Recibar Inc.',
        address: '4031 Colonel Glenn Hwy, Suite 450 Beavercreek Township, Ohio 45431',
        email: 'support@recibar.biz',
        phoneNumber: '+1 (740) 659-7403'
    }
}

const COLOR_BY_HOST = {
    'helpbill.co': '#9E80FF',
    'gotbilled.co': '#749C75',
    'billedme.info': '#246EB9',
    'igotbilled.help': '#5B507A',
    'billed.help': '#E85D75',
    'billsupp.me': '#E6C351',
    'probill.help': '#37323E',
};

initAnalytics();

function App() {
    const host = window.location.host;
    const legalDataByHost = LEGAL_DATA[host] || LEGAL_DATA['billedme.info'];
    const legalName = legalDataByHost.entity;

    useEffect(()=> {
        AmplitudeWrapper('billTicketOpen', {
            result: legalName,
        });

        if (host === 'probill.help') {
            document.querySelectorAll('[rel="icon"]')[0].setAttribute('href',  `${process.env.PUBLIC_URL}/favicon-probill.png`)
        }
    }, []);

    const [isShowModal, toggleModal] = useState(false);
    const [language, setLanguage] = useState('eng');

    const handleChangeLanguage = (lang) => {
        setLanguage(lang);
    };

    const templateColor = COLOR_BY_HOST[host] || '#37323E';

    return (
        <div className="app">
            <Header
                language={language}
                cb={handleChangeLanguage}
            />
            <Description
                translations={translations[language]}
            />
            <Form
                toggleModal={toggleModal}
                translations={translations[language]}
                templateColor={templateColor}
            />
            <Advantages
                translations={translations[language]}
                templateColor={templateColor}
            />
            <Modal
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                translations={translations[language]}
            />
            <Footer
                data={legalDataByHost}
                templateColor={templateColor}
            />
        </div>
    );
}

export default App;
